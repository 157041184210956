<template>
  <v-container fluid grid-list-md>
    <v-card>
      <v-card-title>
        {{
          $config.parental_control_type === "roomnumber"
            ? $t("vod.protected.roomNumberMessage")
            : $t("vod.protected.globalMessage")
        }}
      </v-card-title>
      <v-form class="px-3">
        <v-text-field
          v-if="$config.parental_control_type === 'roomnumber'"
          append-icon="mdi-map-marker"
          name="room"
          :label="$t('login.room')"
          type="text"
          v-model="room"
          required
          :rules="roomRules"
        ></v-text-field>
        <v-text-field
          v-else
          append-icon="mdi-lock"
          name="password"
          type="password"
          v-model="password"
          required
          :rules="passRules"
        ></v-text-field>
      </v-form>
      <v-card-text>
        <v-btn
          text
          block
          color="primary"
          :disabled="!valid"
          :to="getMovieRoute()"
          >{{ $t("modal.ok") }}</v-btn
        >
        <v-btn
          text
          block
          color="primary"
          class="mt-2"
          :to="{ name: 'browse' }"
          >{{ $t("modal.cancel") }}</v-btn
        >
      </v-card-text>
    </v-card>
  </v-container>
</template>
<script>
import { mapState } from "vuex";

export default {
  data() {
    return {
      roomRules: [(v) => !!v || this.$t("general.roomRequired")],
      passRules: [(v) => !!v || this.$t("general.passwordRequired")],
      room: "",
      password: "",
    };
  },
  computed: {
    ...mapState({
      authResult: ({ session }) => session.authResult,
    }),
    valid() {
      return (
        this.room === this.authResult.room ||
        this.password === this.$config.parental_control_pass
      );
    },
  },
  methods: {
    getMovieRoute() {
      return {
        name: "movieDetail",
        params: {
          movieId: this.$route.params.movieId,
        },
      };
    },
  },
};
</script>
